const privacyPolicyText = `
باستخدامك للموقع فإنك توافق على الشروط والأحكام التالية:
جميع المحتويات المعروضة على الموقع، سواء كانت مقالات كتابية أو مرئية، بما في ذلك الأخبار والإعلانات أو المقالات التي تشرح المفاهيم، تهدف بشكل أساسي إلى التعليم وتوسيع الفهم وتثقيف المستخدم العربي في مجال العملات الرقمية المشفرة، ولا يُقصد بها أن تكون استشارات مالية أو توصيات للاستثمار.
لذلك، نحن غير مسؤولين عن كيفية استخدامك لهذه المعلومات، بما في ذلك أي معاملات تجارية أو مالية.
تتحمل أنت المسؤولية الكاملة عن كيفية الاستفادة من هذه المعلومات، ولا يمكننا تحمل أي مسؤولية بشأن استخدامك لها.
أي خسارة قد تحدث، هي مسؤوليتك وحدك، ولا يمكن تحميلنا أي تبعات لها.
المحفظة هي محفظتك، والأموال هي أموالك، ونحن لا نتحمل أي مسؤولية عن ذلك فيما يخص المصادر والعلامات التجارية التي تظل ملكًا لأصحابها.
المقالات المنشورة على موقع CamelBits تعبر عن آراء كتابها فقط ولا تمثل رأي الموقع.
الموقع غير مسؤول عن أي أضرار قد تنشأ نتيجة لاستخدامك لهذه المقالات.
الموقع لا يتحمل أي مسؤولية فيما يخص استثمار أو إدارة أموالك، ولا يتحمل تبعات أي خسائر قد تحدث.
قد ينشر موقع CamelBits إعلانات أو مقالات ممولة من جهات خارجية، وبالتالي فإننا نبرئ مسؤوليتنا عن أي أضرار قد تحدث نتيجة لاستخدام المحتوى الإعلاني.
ننصح المستخدمين بالقيام ببحث مستقل قبل اتخاذ أي قرارات. الشروط والأحكام المذكورة أعلاه تنطبق على جميع مستخدمي الموقع.
`;

export default privacyPolicyText;
